import la from '../LazyComponent';

const KYC = la(() => import('@/pages/ReportsKYC'));
const Deposits = la(() => import('@/pages/ReportsDeposits'));
const Withdrawals = la(() => import('@/pages/ReportsWithdrawals'));
const WholesaleAudit = la(() => import('@/pages/ReportsWholesaleAudit'));
const AccountAudit = la(() => import('@/pages/ReportsAccountAudit'));
const CopyTrading = la(() => import('@/pages/ReportsCopyTrading'));
const Performance = la(() => import('@/pages/ReportsPerformance'));

const ReportRoutes = [
  {
    path: '/reports',
    element: KYC,
    name: 'menu.Reports',
    meta: {
      key: 'menu.sws.reports',
    },
  },
  {
    path: '/reports/kyc',
    element: KYC,
    name: 'menu.KYC',
    meta: {
      key: 'menu.sws.reports.KYC',
    },
  },
  {
    path: '/reports/deposits',
    element: Deposits,
    name: 'menu.Deposits',
    meta: {
      key: 'menu.sws.reports.Deposits',
    },
  },
  {
    path: '/reports/withdrawals',
    element: Withdrawals,
    name: 'menu.Withdrawals',
    meta: {
      key: 'menu.sws.reports.Withdrawals',
    },
  },
  {
    path: '/reports/wholesaleAudit',
    element: WholesaleAudit,
    name: 'menu.Wholesale',
    meta: {
      key: 'menu.sws.reports.wholesale',
    },
  },
  {
    path: '/reports/account',
    element: AccountAudit,
    name: 'menu.Account',
    meta: {
      key: 'menu.sws.reports.accountAudit',
    },
  },
  {
    path: '/reports/copyTrading',
    element: CopyTrading,
    name: 'menu.Copy Trading',
    meta: {
      key: 'menu.sws.reports.copyTrading',
    },
  },
  {
    path: '/reports/performance',
    element: Performance,
    name: 'menu.Performance',
    meta: {
      key: 'menu.sws.reports.perf',
    },
  },
];
export default ReportRoutes;
