import request from '@/utils/request/index';

// 获取语言列表
export const getLangListData = () => request.get('/api/v1/translations/languages');
// 更改当前监管
export const changeRegulatorData = (regulator: string) =>
  request.post('/api/v1/regulators/current', { regulator });
// loginApi
export const toLogin = (data: {}) => request.post('/api/v1/auth/session/login', data);

export const toLoginOut = () => request.post('/api/v1/auth/session/logout');

export const toShowPhoneOrEmail = (params) => request.get('/api/v1/decrypt/email-or-phone', params);
// user info
export const getUserInfoData = () => request.post('/api/v1/user/getUserInfo');
// ringCentral login
/**
 *
 * @param state - redirect url
 * @returns
 */
export const ringCentralLogin = (params: { state: string }) =>
  request.get('/api/v1/communication/telephony/auth/login-url', params);
// get all enums
export const getAllEnumsData = () => request.get('/api/v1/enums/all');
// get search enums
export const getSearchEnumsData = () => request.get('api/v1/enums/options');
// get Tags
export const getListTags = () => request.get('/api/v1/users/extends/tags');
// get tiers
// export const getTiersData = () => request.get('/api/v1/tiers');
// get refreshToken
export const getRefreshToken = (params) =>
  request.post('/api/v1/auth/session/refresh/token', params);
// 心跳
export const upHeart = () => request.post('/api/v1/beat', {});

// admin entry
export const otherEntry = (params) =>
  request.post('/api/v1/auth/session/internal/system/login', params);

// custom filter
export const getModuleFilterData = (params: { source: string }) =>
  request.get('/api/v1/viewFilter/list', params);

export const saveModuleFilterData = (params: {
  filterName: string;
  filterSource: string;
  filterData: { [key: string]: any };
}) => request.post('/api/v1/viewFilter/save', params);

export const delModuleFilterData = (filterId: string) =>
  request.post('/api/v1/viewFilter/delete', { filterId });
export const updateModuleGuide = (params) => request.post('/api/v1/user/updateLoginGuide', params);

// 发送邮箱验证码
export const sendEmailCode = (params) =>
  request.post('/api/v1/mfa/sendEmailVerificationCode', params);

// 获取二维码信息
export const getQrCode = () => request.get('/api/v1/twofactor/status');

// 绑定设备
export const bindDevice = (params) => request.post('/api/v1/twofactor/enable', params);

// 校验验证码
export const preValidate = (params) =>
  request.post(`/api/v1/twofactor/preValidate?token=${params.token}&flag=${params.flag}`);

// 更换设备
export const changeDevice = (params) => request.post('/api/v1/twofactor/changeDevice', params);
